$pkt-icon-size: 1em;
$pkt-entityicon-size: 2em;

%pkt-icon {
    height: $pkt-icon-size;
    display: inline-block;
    vertical-align: text-top;
}

img.pkt-icon {
    @extend %pkt-icon;
}

img.pkt-entityicon {
    @extend %pkt-icon;
    min-height: 1em;
    max-height: $pkt-entityicon-size;
    height: auto;
    vertical-align: bottom;

    &-none {
        height: $pkt-entityicon-size;
        width: $pkt-entityicon-size;
    }
}

@font-face {
    font-family: "poketools";
    src: url("fonts/poketools.woff") format("woff"),
    url("fonts/poketools.ttf") format("truetype"),
    url("fonts/poketools.svg#poketools") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "pokedollar";
    src: url("fonts/pokedollar.woff") format("woff"),
    url("fonts/pokedollar.ttf") format("truetype"),
    url("fonts/pokedollar.svg#Pokedollar") format("svg");
    font-weight: normal;
    font-style: normal;
}

.pkt-text {
    font-family: "pokedollar", $font-family-base;
}

$pkt-icons: (
    pokedollar: '$',
    damageclass-physical: '\e001',
    damageclass-special: '\e002',
    damageclass-status: '\e003',
);

i.pkt-icon {
    font-family: "poketools" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    color: $body-color;

    @each $name, $char in $pkt-icons {
        &-#{$name}::before {
            content: '#{$char}';
        }
    }

    &-damageclass {
        $color-damage-class: (
            physical: #C92112,
            special: #4F5870,
            status: #8C888C
        );

        @each $damage-class in map_keys($color-damage-class) {
            &-#{$damage-class} {
                // This is styled similarly to the type emblems.
                vertical-align: middle;
                width: 2em;
                height: 1em;
                padding-left: 0.5em;
                padding-right: 0.5em;
                text-align: center;
                font-weight: bold;
                border-radius: 0.5rem;
                background-color: map_get($color-damage-class, $damage-class);

                @if (lightness(map_get($color-damage-class, $damage-class)) > 80%) {
                    color: $gray-700;
                } @else {
                    color: $gray-100;
                }
            }
        }
    }
}
