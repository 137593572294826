@import "src/assets/styles/bootstrap";

.pkt-quicksearch {
    @extend .ml-auto;
    @extend .my-2;
    @extend .my-lg-0;

    display: flex;

    button.btn[type=submit] {
        @extend .my-2;
        @extend .my-sm-0;
        @extend .ml-sm-1;
    }
}
