@import "src/assets/styles/bootstrap";
@import "src/assets/styles/icons";

.pkt-entitylabel {
    display: inline;
    white-space: nowrap;

    .pkt-entitylabel-icon {
        @extend .mr-1;
        display: inline;
    }

    .pkt-entitylabel-text {
        display: inline;
    }
}
