@import "src/assets/styles/bootstrap";

.pkt-navbar-main {
    img {
        height: $navbar-brand-height;
    }

    .pkt-logo {
        @extend .p-0;
        height: $navbar-brand-height;
    }

    .btn {
        background-color: $gray-100;
    }

    .nav-item {
        &, .nav-link {
            color: rgba(255, 255, 255, 0.75);
        }

        .dropdown-item.nav-link {
            color: $dropdown-link-color;
        }
    }
}

footer {
    @extend .mt-auto;
}
